<template>
  <div>
    <Section1 :keyWord="'La mejor solución para conseguir un minipréstamo'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4">
        <strong>Mini Préstamo Rápido Online</strong>
      </h2>
      <p>
        Un <strong>mini préstamo</strong> es la fórmula más efectiva para
        conseguir dinero urgente en tu cuenta. Afrontar un pago, financiar un
        proyecto personal, acceder a una formación… Sea cual sea tu objetivo
        puedes hacerlo realidad en tan solo unos minutos a través de los
        <a href="https://ibancar.com/prestamo-por-tu-coche">
          préstamos por tu coche
        </a>
        online de Ibancar.
      </p>
      <p>
        Desde 300€ hasta 6.000€ con tu coche como garantía. No cambiamos la
        titularidad, ya que el coche sólo es la garantía para poder solventar
        algún inconveniente que tengas
      </p>

      <h3 class="naranja">
        <strong
          >Créditos Online, Préstamos Rápidos y Mini Préstamos con
          Ibancar</strong
        >
      </h3>

      <p>
        Ibancar es una entidad de crédito moderna y flexible que se adapta a la
        perfección a las necesidades de sus clientes. Conseguir un
        <strong>mini préstamo</strong> en Ibancar es mucho más sencillo que
        solicitar un crédito en una entidad financiera tradicional.
      </p>
      <p>
        Todo el proceso se completa de manera 100% online, no necesitas a otras
        personas que actúen como avales, puedes conseguir tu dinero aunque hayas
        sido incluido en algún fichero de impagados como el de la Asnef y además
        tendrás el ingreso en tu cuenta en en el mismo día. ¡Así de sencillo!
      </p>

      <h3 class="naranja"><strong>Solicita tu préstamo online </strong></h3>
        <p>Vamos a repasar los pasos a seguir para solicitar tu <strong>mini préstamo online</strong> en Ibancar de manera sencilla y en cuestión de minutos:</p>
        <p>1.     Piensa en la cantidad de dinero que quieres solicitar a través del <strong>mini préstamo online</strong> de Ibancar. Podemos prestarte desde 600€ </p>
        <p>2.     Completa el formulario de la web con los datos requeridos.</p>
        <p>3.     En tan solo unos minutos recibirás una propuesta de crédito personalizada en tu correo electrónico.</p>
        <p>4.     Acepta la propuesta y aporta la documentación necesaria para formalizar el acuerdo.</p>
        <p>5.     Revisa tu cuenta corriente. En menos de 24 horas tendrás el dinero disponible.</p>

      <h3 class="naranja">
        <strong>¿Cómo funciona?</strong>
      </h3>
            <p>En el <strong>mini préstamo online</strong> de Ibancar tu coche actúa como el aval para tu crédito. A diferencia de otras entidades, en Ibancar vas a poder seguir utilizando tu vehículo sin ningún tipo de restricción.</p>
            <p>·       No vas a tener que dejar tu coche en un depósito ni pagar un renting o un alquiler para conducirlo.</p>
            <p>·       No tendrás que entregar una copia de las llaves ni la documentación original del vehículo.</p>
            <p>·       No se realiza ningún cambio en la titularidad del coche.</p>
            <p>·       Vas a poder amortizar tu <strong>mini préstamo</strong> desde la primera cuota.</p>
            <p>·       Acceso a un pack de servicios exclusivos entre los que se incluye un gestor personal para asistirte en la solicitud y una revisión técnica anual de mantenimiento.</p>

      <h3 class="naranja">
        <strong>¿Qué documentación necesito aportar para acceder al mini préstamo online de Ibancar?</strong>
      </h3>
     <p>Lo primero que debes saber es que puedes enviar toda la documentación para 
         acceder a  tu <strong>mini préstamo online</strong> en Ibancar tanto por correo electrónico como por WhatsApp. Además, tienes abierto nuestro teléfono de atención al cliente para resolver cualquier duda que pueda surgirte.</p>
<p>Cuando realices la solicitud aportarás el número de la matrícula del vehículo y tus datos de contacto. Cuando aceptes la propuesta de<strong> mini préstamo</strong> se completará el proceso aportando los siguientes documentos:</p>
<p>·       DNI</p>
<p>·    Justificante de ingresos</p>
<p>·    Foto de la Ficha técnica</p>
<p>·    Foto del cuentakilómetros + DNI</p>
<p>·    4 fotos o un video del coche</p>
<p>Ten preparada la documentación cuando solicites el dinero  y conseguirás tu <strong>mini préstamo online</strong> en Ibancar en un tiempo récord. </p>


      

      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
        <div class="flex-column pt-5">
          <a href="#formulario" class="btn btn-orange text-center p-2"
            >Solicitar préstamo</a
          >
        </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 />
    <!-- acordeón de preguntas -->
    <div class="relleno"></div>

    <div class="cards-prestamo">
      <div
        class="
          d-flex
          flex-row
          justify-content-center
          align-items-center
          flex-wrap
          pt-2
        "
      >
        <div class="padding">
          <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
        <div class="card m-4">
          <a href="../prestamo-por-tu-coche" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
              <span class="caret-sign">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Entra aquí y conoce todo lo que debes saber para conseguir un
                préstamo por tu coche y resolver tus problemas.
              </p>
            </div>
          </a>
        </div>
        <div class="card m-4">
          <a href="../credito-por-tu-coche" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">Crédito por tu coche</h5>
              <span class="caret-sign">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Consigue un crédito por tu coche. Crédito por tu coche Online
              </p>
            </div>
          </a>
        </div>
        <div class="card m-4">
          <a href="../dinero-rapido-con-tu-coche" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Dinero rápido con tu coche
              </h5>
              <span class="caret-sign">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Consigue dinero fácil con tu coche. Préstamo rápido y fácil por
                tu coche. Préstamos online por tu coche.
              </p>
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4">
          <a href="../credito-por-tu-coche-en-madrid" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Crédito por tu coche en Madrid
              </h5>
              <span class="caret-sign-city">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Crédito por tu coche en Madrid. Recibe dinero por tu coche en
                Madrid
              </p>
            </div>
          </a>
        </div>
        <div class="card m-4">
          <a href="../credito-por-tu-coche-en-sevilla" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Crédito por tu coche en &nbsp; Sevilla
              </h5>
              <span class="caret-sign-city">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Credito por tu coche en Sevilla. Recibe dinero por tu coche en
                Sevilla
              </p>
            </div>
          </a>
        </div>
        <div class="card m-4">
          <a href="../credito-por-tu-coche-en-valencia" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Crédito por tu coche en Valencia
              </h5>
              <span class="caret-sign-city">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Crédito por tu coche en Valencia. Recibe dinero por tu coche en
                Valencia.
              </p>
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4">
          <a href="../credito-aval-coche-las-palmas" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Crédito aval coche Las &nbsp; Palmas
              </h5>
              <span class="caret-sign-city">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Consigue dinero usando tu coche como aval sin cambiar de
                titularidad.
              </p>
            </div>
          </a>
        </div>
        <div class="card m-4">
          <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Préstamos con Asnef y nómina rápidos
              </h5>
              <span class="caret-sign-city">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Ya puedes conseguir préstamos con Asnef y nómina de manera muy
                sencilla.
              </p>
            </div>
          </a>
        </div>
        <div class="card m-4">
          <a
            href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular"
            class="no"
          >
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Préstamos con aval de coche sin cambiar de titular
              </h5>
              <span class="caret-sign-city">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Consigue dinero con tu coche sin cambiar la titularidad.
              </p>
            </div>
          </a>
        </div>
      </div>

      <!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4">
          <a
            href="../prestamos-con-aval-del-coche-de-mas-de-10-anos"
            class="no"
          >
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Préstamos con aval del coche de mas de 10 años
              </h5>
              <span class="caret-sign-city">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Tenemos toda la información que necesitas leer sobre esto.
              </p>
            </div>
          </a>
        </div>
        <div class="card m-4">
          <a href="../prestamos-por-tu-coche-alicante" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Préstamos por tu coche Alicante
              </h5>
              <span class="caret-sign-city">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                ¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos
                la solución, entra aquí.
              </p>
            </div>
          </a>
        </div>
        <div class="card m-4">
          <a href="../prestamos-aval-coche-valencia" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Préstamos aval coche &nbsp; Valencia
              </h5>
              <span class="caret-sign-city">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Consigue dinero con tu coche sin cambiar la titularidad.
              </p>
            </div>
          </a>
        </div>
      </div>

      <!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4">
          <a href="../credito-aval-coche-con-asnef" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">
                Crédito aval coche con Asnef
              </h5>
              <span class="caret-sign-city">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Consigue dinero con tu coche estando en Asnef.
              </p>
            </div>
          </a>
        </div>
        <div class="card m-4">
          <a href="../credito-coche" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">Crédito con aval</h5>
              <span class="caret-sign">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z"
                    fill="#686B6F"
                  />
                </svg>
              </span>
              <p class="card-text pl-1 pt-2">
                Tenemos la solución para un crédito por tu coche con aval.
              </p>
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
        <div class="flex-column p-5">
          <a href="#formulario" class="btn btn-orange text-center p-2"
            >Solicitar préstamo</a
          >
        </div>
      </div>
    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from "@/components/Home/Section1.vue";
import { useMeta } from "vue-meta";
import store from "@/store";
import Section2 from "@/components/Home/Section2.vue";
import CreditoCocheS4 from "@/components/CreditoCoche/CreditoCocheS4.vue";

// @ is an alias to /src
export default {
  name: "MiniPrestamoRapido",
  components: {
    Section1,
    Section2,
    CreditoCocheS4,
  },
  setup() {
    useMeta({
      title: "Mini Préstamo Rápido Online | Ibancar",
      meta: [
      {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
      {
          name: "description",
          content:
            "Un mini préstamo es la fórmula más efectiva para conseguir dinero urgente en tu cuenta",
        },
        { name: "keywords", content: "miniprestamo" },
      ],
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign,
.caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-between(xs, sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md, lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl, xxl) {
}
</style>